import { combineReducers, Reducer, UnknownAction } from 'redux';

import { authReducer } from 'src/modules/auth/redux';
import { bankAccountsReducer } from 'src/modules/bankAccounts/redux';
import { cardsReducer } from 'src/modules/cards/redux';
import { invitationReducer } from 'src/modules/invitation/redux';
import { paymentsReducer } from 'src/modules/payments/redux';

import { clearStorage } from 'src/helpers/storage';

import { clearToken } from 'src/utils/authUtils';

import { ReduxStore } from 'src/types';

import { commonReducer } from '../modules/common/redux';

export default ((state: ReduxStore, action: UnknownAction) => {
  if (action.type === 'module/auth/logout') {
    clearToken('*');
    clearStorage();
  }
  return combineReducers({
    'module/auth': authReducer,
    'module/bankAccounts': bankAccountsReducer,
    'module/common': commonReducer,
    'module/invitation': invitationReducer,
    'module/payments': paymentsReducer,
    'module/cards': cardsReducer,
  })(state, action);
}) as Reducer;
