import React, { PropsWithChildren } from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { captureException as SentryCaptureException } from '@sentry/react';

import { ErrorBoundaryProps } from 'src/types';

import ErrorFallbackRender from './ErrorFallbackRender';

const ErrorBoundary: React.FC<PropsWithChildren<ErrorBoundaryProps>> = ({
  children,
  ...props
}) => {
  return (
    <ReactErrorBoundary
      fallbackRender={({ error }) => (
        <ErrorFallbackRender {...props} error={error} />
      )}
      onError={error => SentryCaptureException(error)}
    >
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
