import React from 'react';
import { Button, ButtonVariant } from '@brasshq/ui-kit';
import cx from 'classnames';

import { AlertIcon } from 'src/assets/svgs';

import { CommonContext } from 'src/modules/common/context';

import { webMessenger } from 'src/helpers';
import { ZendeskAuthType } from 'src/helpers/webMessenger';

import { BackButton } from './BackButton';

import styles from './ErrorBoundary.module.scss';

interface ErrorFallbackRenderProps {
  forRoute?: boolean;
  useBackButton?: boolean;
  className?: string;
  error?: Error;
}

const ErrorFallbackRender: React.FC<ErrorFallbackRenderProps> = ({
  forRoute,
  useBackButton,
  className,
  error,
}) => {
  const { profile } = React.useContext(CommonContext);

  React.useEffect(() => {
    webMessenger.initialize(
      profile ? ZendeskAuthType.User : ZendeskAuthType.Anonymous,
    );
  }, []);

  return (
    <div
      className={cx(styles.ErrorBoundary, className, {
        [styles.ErrorBoundary_for_route]: forRoute,
      })}
    >
      <AlertIcon />
      <h1>{error?.message || 'Something went wrong'}</h1>
      <p>There was a problem processing the request. Please try again</p>
      <p>
        To report an issue,{' '}
        <Button
          onClick={() => webMessenger.show()}
          variant={ButtonVariant.Text}
        >
          click here
        </Button>
      </p>
      {useBackButton ? (
        <BackButton />
      ) : (
        <Button
          onClick={() => window.location.reload()}
          variant={ButtonVariant.Primary}
        >
          Reload
        </Button>
      )}
    </div>
  );
};

export default ErrorFallbackRender;
