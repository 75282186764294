import { createSlice } from '@reduxjs/toolkit';
import { omit } from 'lodash';

import { clearToken, setToken } from 'src/utils/authUtils';

import { Profile } from 'src/types';
import { ReduxStore } from 'src/types/redux';

import { fetchActiveSession } from './actions';
import { login } from '.';

type AuthReducerState = ReduxStore['module/auth'];

const initialState: AuthReducerState = {
  isLoggedIn: false,
  hasSessionTimedOut: false,
  isFetchingSession: false,
  profile: null,
};

const authSlice = createSlice({
  name: 'module/auth',
  initialState,
  reducers: {
    logout: () => {
      return initialState;
    },

    setSessionTimeout: (state, action) => {
      state.hasSessionTimedOut = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        const { token } = action.payload;
        clearToken('*');
        setToken('auth', token);
        return Object.assign(state, {
          isLoggedIn: true,
          hasSessionTimedOut: false,
          profile: omit(action.payload, ['token', 'token_expiry']) as Profile,
        });
      })
      .addCase(fetchActiveSession.pending, state => {
        return Object.assign(state, {
          isFetchingSession: true,
        });
      })
      .addCase(fetchActiveSession.rejected, state => {
        return Object.assign(state, {
          isFetchingSession: false,
        });
      })
      .addCase(fetchActiveSession.fulfilled, (state, action) => {
        const { token } = action.payload.data;
        clearToken('*');
        setToken('auth', token);
        return Object.assign(state, {
          isLoggedIn: true,
          isFetchingSession: false,
          hasSessionTimedOut: false,
          profile: omit(action.payload?.data, [
            'token',
            'token_expiry',
          ]) as Profile,
        });
      });
  },
});

export const { setSessionTimeout, logout } = authSlice.actions;

export const authReducer = authSlice.reducer;
