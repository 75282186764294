import * as ROUTES from 'src/data/apiRoutes';

import { DefaultFetchParams } from 'src/types';

import { BrassApiService } from 'src/services';

export const getPayslips = (params?: DefaultFetchParams) =>
  BrassApiService.get(ROUTES.payslipsRoute, { params });

export const postExportPayslipsReport = (data?: DefaultFetchParams) =>
  BrassApiService.post(ROUTES.payslipsRoute, data);

export const postGeneratePayslip = (id: string) =>
  BrassApiService.post(`${ROUTES.payslipsRoute}/${id}`);
