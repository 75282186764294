import { store } from 'src/redux/store';

import axios from 'src/helpers/axios';

import * as env from 'src/data/env';

import { getToken } from 'src/utils/authUtils';
import { setRequestHeaders } from 'src/utils/genericUtils';

const BrassApiService = axios.create({
  baseURL: env.API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

BrassApiService.interceptors.request.use(req => {
  const appState = store.getState();
  const hasSessionTimedOut = appState['module/auth'].hasSessionTimedOut;
  const apiKey = env.API_KEY;
  const authToken = getToken('auth');
  const otpToken = getToken('otp');
  req = setRequestHeaders(req, { Authorization: `Bearer ${apiKey}` });

  if (authToken && !hasSessionTimedOut) {
    req.headers!.Authorization = `Bearer ${authToken}`;
  } else {
    req.headers!.Authorization = `Bearer ${apiKey}`;
  }
  if (otpToken) req.headers!.Authorization = `Bearer ${otpToken}`;

  return req;
});

export default BrassApiService;
