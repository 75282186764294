import {
  changePasswordRoute,
  employeeProfileRoute,
  otpRoute,
  resetPasswordRoute,
  verifyPasswordHashRoute,
} from 'src/data/apiRoutes';
import { loginRoute } from 'src/data/apiRoutes';

import { IObject } from 'src/types';

import { BrassApiService } from 'src/services';

import { RDTResetPassword } from './types';
import { RDTUserLogin } from './types';

export const postLogin = (user: RDTUserLogin, params?: IObject) => {
  return BrassApiService.post(loginRoute, user, { params });
};

export const postResetPassword = (data: RDTResetPassword) =>
  BrassApiService.post(resetPasswordRoute, data);

export const getActiveSession = () =>
  BrassApiService.get(employeeProfileRoute, {
    headers: {
      'Cache-Control': 'no-cache',
    },
  });
export const postVerifyResetPasswordHash = (hash: string) =>
  BrassApiService.get(verifyPasswordHashRoute(hash));

export const postChangePassword = (hash: string, password: string) =>
  BrassApiService.post(changePasswordRoute(hash), { password });

export const postSendOtp = (params?: IObject, isLoggedIn?: boolean) =>
  BrassApiService.post(
    otpRoute,
    {},
    {
      params: {
        ...params,
        useOtpToken: !isLoggedIn,
      },
    },
  );
