import axios from 'src/helpers/axios';

import * as env from 'src/data/env';

import { getToken } from 'src/utils/authUtils';
import { setRequestHeaders } from 'src/utils/genericUtils';

const BrassEverVaultServices = axios.create({
  baseURL: env.BRASS_EVERVAULT_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

BrassEverVaultServices.interceptors.request.use(req => {
  const authToken = getToken('auth');

  req = setRequestHeaders(req, { Authorization: `Bearer ${authToken}` });

  return req;
});

export default BrassEverVaultServices;
