import { createSlice } from '@reduxjs/toolkit';

import { fetchActiveSession, login } from 'src/modules/auth/redux';
import { fetchInvitationDetails } from 'src/modules/invitation/redux';

import { isLogoutAction } from 'src/utils/authUtils';

import { ReduxStore } from 'src/types';

import {
  fetchBankList,
  fetchCountriesList,
  fetchIndustries,
  fetchPfaList,
  fetchStatesList,
} from './actions';

type CommonReducerState = ReduxStore['module/common'];

const initialState: CommonReducerState = {
  statesList: {
    data: [],
  },
  countriesList: {
    data: [],
  },

  industries: { data: [] },
  banks: {
    data: [],
  },
  pfas: { data: [] },
  customerCountry: { data: null },
};

const commonSlice = createSlice({
  name: 'module/common',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchStatesList.fulfilled, (state, action) => {
        state.statesList.data = action.payload?.data;
      })
      .addCase(fetchCountriesList.fulfilled, (state, action) => {
        state.countriesList.data = action.payload?.data;
      })
      .addCase(fetchIndustries.fulfilled, (state, action) => {
        state.industries.data = action.payload?.data;
      })
      .addCase(fetchBankList.fulfilled, (state, action) => {
        state.banks.data = action.payload?.data;
      })
      .addCase(fetchPfaList.fulfilled, (state, action) => {
        state.pfas.data = action.payload?.data;
      })
      .addCase(fetchInvitationDetails.fulfilled, (state, action) => {
        state.customerCountry.data =
          action.payload?.data.customer.data.country.data || null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.customerCountry.data =
          action.payload?.customer.data.country.data || null;
      })
      .addCase(fetchActiveSession.fulfilled, (state, action) => {
        state.customerCountry.data =
          action.payload?.data.customer?.data.country.data || null;
      })
      .addMatcher(isLogoutAction, state => ({
        ...state,
        customerCountry: initialState.customerCountry,
      }));
  },
});

export const commonReducer = commonSlice.reducer;
