import { createAsyncThunk } from '@reduxjs/toolkit';

import { AsyncWrapper } from 'src/helpers/asyncWrapper';

import { Profile, WithData } from 'src/types';

import * as api from '../api';
import { RDTUserLogin } from '../types';

export const login = createAsyncThunk<Profile, RDTUserLogin>(
  'auth/login',
  async (user, { rejectWithValue }) => {
    const [data, error] = await AsyncWrapper(
      api.postLogin(user, { useApiKey: true }),
    );
    if (error) return rejectWithValue(error.response?.data);
    return data;
  },
);

export const fetchActiveSession = createAsyncThunk<WithData<Profile>>(
  'auth/fetchActiveSession',
  async (_, { rejectWithValue }) => {
    const [data, error] = await AsyncWrapper(api.getActiveSession());
    if (error) return rejectWithValue(error.response?.data);
    return data;
  },
);
