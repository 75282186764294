import { createSlice } from '@reduxjs/toolkit';

import { isLogoutAction } from 'src/utils/authUtils';

import { ReduxStore } from 'src/types';

import { fetchAccounts } from './actions';

type BankAccountsState = ReduxStore['module/bankAccounts'];

const initialState: BankAccountsState = {
  accounts: {
    data: [],
    loading: false,
    pagination: {
      total_pages: 1,
      per_page: 10,
      current_page: 1,
    },
  },
  salaryAccountId: null,
};

const bankAccountSlice = createSlice({
  name: 'module/bankAccounts',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchAccounts.pending, state => {
        state.accounts.loading = true;
      })
      .addCase(fetchAccounts.fulfilled, (state, action) => {
        state.accounts = {
          data: action.payload?.data || null,
          loading: false,
        };
        state.salaryAccountId = action.payload?.meta?.salary_account_id;
      })
      .addCase(fetchAccounts.rejected, (state, action: any) => {
        state.accounts = {
          data: [],
          loading: false,
          error: action.payload?.error.description,
        };
      })
      .addMatcher(isLogoutAction, () => initialState);
  },
});

export const bankAccountsReducer = bankAccountSlice.reducer;
