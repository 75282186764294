import { createAsyncThunk } from '@reduxjs/toolkit';

import { AsyncWrapper } from 'src/helpers/asyncWrapper';

import {
  DefaultFetchParams,
  Invitation,
  PayrollProfile,
  WithData,
} from 'src/types';

import { getInvitation, getPayrollProfile } from '../api';

export const fetchPayrollProfile = createAsyncThunk<
  WithData<PayrollProfile>,
  string
>('invitation/payrollProfile', async (id: string, { rejectWithValue }) => {
  const [result, error] = await AsyncWrapper(getPayrollProfile(id));
  if (error) return rejectWithValue(error.response?.data);
  return result;
});

export const fetchInvitationDetails = createAsyncThunk<
  WithData<Invitation>,
  { id: string; params?: DefaultFetchParams }
>('invitaton/details', async ({ id, params }, { rejectWithValue }) => {
  const [result, error] = await AsyncWrapper(
    getInvitation(id, { ...params, include: 'customer,customer.country' }),
  );
  if (error) return rejectWithValue(error.response?.data);
  return result;
});
