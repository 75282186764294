export const ENV: string = import.meta.env.NODE_ENV!;

export const API_URL: string = import.meta.env.VITE_API_URL!;

export const API_KEY: string = import.meta.env.VITE_API_KEY!;

export const BRASS_EVERVAULT_URL: string = import.meta.env
  .VITE_BRASS_EVERVAULT_URL!;

export const COOKIE_DOMAIN = import.meta.env.VITE_COOKIES_DOMAIN!;

export const SENTRY_KEY = import.meta.env.VITE_SENTRY_PUBLIC_KEY;

export const BRASS_APP_REDUX_PERSIST_KEY =
  import.meta.env.VITE_BRASS_APP_REDUX_PERSIST_KEY! || 'DEMO_KEY';

export const HOMEPAGE_URL: string = import.meta.env.VITE_BRASS_HOMEPAGE_URL!;

export const BRASS_MONEY_HOMEPAGE_URL: string = import.meta.env
  .VITE_BRASS_MONEY_HOMEPAGE_URL!;

export const SENTRY_TRACES_SAMPLE_RATE = import.meta.env
  ?.VITE_SENTRY_TRACES_SAMPLE_RATE
  ? Number(import.meta.env?.VITE_SENTRY_TRACES_SAMPLE_RATE)
  : 0;

export const SENTRY_RELAYS_SESSION_SAMPLE_RATE = import.meta.env
  ?.VITE_SENTRY_RELAYS_SESSION_SAMPLE_RATE
  ? Number(import.meta.env.VITE_SENTRY_RELAYS_SESSION_SAMPLE_RATE)
  : 0;

export const SENTRY_ON_ERROR_SAMPLE_RATE = import.meta.env
  ?.VITE_SENTRY_ON_ERROR_SAMPLE_RATE
  ? Number(import.meta.env.VITE_SENTRY_ON_ERROR_SAMPLE_RATE)
  : 0;

export const SENTRY_ENV = import.meta.env?.VITE_SENTRY_ENV || ENV;

export const BRASS_BANKING_APP_IOS = import.meta.env
  .VITE_BRASS_BANKING_APP_IOS!;

export const BRASS_BANKING_APP_ANDROID = import.meta.env
  .VITE_BRASS_BANKING_APP_ANDROID!;

// Zendesk
export const ZENDESK_URL: string = import.meta.env.VITE_ZENDESK_URL!;
export const ZENDESK_URL_KEY: string = import.meta.env.VITE_ZENDESK_URL_KEY!;
export const ZENDESK_ID: string = import.meta.env.VITE_ZENDESK_ID!;
