import React from 'react';

interface PageLoaderContextValue {
  forInnerPages: boolean;
}

const defaultValue = {
  forInnerPages: false,
};

const PageLoaderContext =
  React.createContext<PageLoaderContextValue>(defaultValue);

const PageLoaderProvider: React.FC<
  React.PropsWithChildren<Partial<PageLoaderContextValue>>
> = ({ forInnerPages = false, children }) => {
  return (
    <PageLoaderContext.Provider value={{ forInnerPages }}>
      {children}
    </PageLoaderContext.Provider>
  );
};

export default PageLoaderProvider;

export const usePageLoaderContext = () => {
  const context = React.useContext(PageLoaderContext);

  return context;
};
