import {
  bankListRoute,
  countriesListRoute,
  generateServiceJwtRoute,
  industriesRoute,
  pfaRoute,
  statesListRoute,
  verifyBankAccountRoute,
} from 'src/data/apiRoutes';

import { IObject } from 'src/types';

import { BrassApiService } from 'src/services';

import {
  RDTFetchBankList,
  RDTFetchLists,
  RDTFetchPfaList,
  RDTFetchStatesList,
  RDTVerifyBankAccount,
} from './types';

export const getStatesList = (params?: RDTFetchStatesList) =>
  BrassApiService.get(statesListRoute, { params });

export const getCountriesList = (params?: RDTFetchLists) =>
  BrassApiService.get(countriesListRoute, { params });

export const getIndustries = (params?: RDTFetchLists) =>
  BrassApiService.get(industriesRoute, { params });

export const getBanksList = (params?: RDTFetchBankList) =>
  BrassApiService.get(bankListRoute, { params });

export const getVerifyBankAccount = (params?: RDTVerifyBankAccount) =>
  BrassApiService.get(verifyBankAccountRoute, { params });

export const getPfaList = (params?: RDTFetchPfaList) =>
  BrassApiService.get(pfaRoute, { params });

export const postGenerateServiceJwt = (params: IObject) =>
  BrassApiService.post(generateServiceJwtRoute, params);
