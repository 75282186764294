import * as ROUTES from 'src/data/apiRoutes';

import { DefaultFetchParams } from 'src/types';

import { BrassApiService } from 'src/services';

import { RDTRegisterEmployee, RDTSendInvitation } from './types';

export const postRegisterEmployee = (data: RDTRegisterEmployee) =>
  BrassApiService.post(ROUTES.registerEmployeeRoute, data);

export const getPayrollProfile = (id: string) =>
  BrassApiService.get(`${ROUTES.payrollProfileRoute}/${id}`);

export const postSendInvitation = (data: RDTSendInvitation) =>
  BrassApiService.post(ROUTES.joinEmployeeRoute, data);

export const getInvitation = (id: string, params: DefaultFetchParams) =>
  BrassApiService.get(`${ROUTES.employeeInviteRoute}/${id}`, { params });
