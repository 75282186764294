import { createAsyncThunk } from '@reduxjs/toolkit';

import { AsyncWrapper } from 'src/helpers/asyncWrapper';

import { DefaultFetchParams, Pagination, Payslip } from 'src/types';

import { getPayslips } from '../api';

export const fetchPayslips = createAsyncThunk<
  {
    data: Payslip[];
    meta: {
      pagination: Pagination;
    };
  },
  DefaultFetchParams | undefined
>('payments/payslips', async (params, { rejectWithValue }) => {
  const [result, error] = await AsyncWrapper(
    getPayslips({
      ...params,
      include: `
      payment,payroll_run,payroll_user_profile.business.customer.addresses,payroll_user_profile.salary_account.bank
      `,
    }),
  );
  if (error) return rejectWithValue(error.response?.data);
  return result;
});
