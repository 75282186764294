import { applyMiddleware, configureStore } from '@reduxjs/toolkit';
import { createReduxEnhancer as SentryCreateReduxEnhancer } from '@sentry/react';
import { GetDefaultMiddleware } from 'node_modules/@reduxjs/toolkit/dist/getDefaultMiddleware';
import logger from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import { PersistPartial } from 'redux-persist/es/persistReducer';

import { REDUX_PERSIST_CONFIG } from 'src/config/reduxPersist';

import { ReduxStore } from 'src/types/redux';

import reducers from './reducers';

const persistedReducer = persistReducer<ReduxStore>(
  REDUX_PERSIST_CONFIG,
  reducers,
);

const sentryReduxEnhancer = SentryCreateReduxEnhancer();

const middlewareConfig = (
  getDefaultMiddleware: GetDefaultMiddleware<ReduxStore & PersistPartial>,
) => {
  const isInDevelopmentMode = import.meta.env.DEV;
  const options = {
    immutableCheck: false,
    serializableCheck: false,
  };
  if (isInDevelopmentMode) return getDefaultMiddleware(options).concat(logger);
  return getDefaultMiddleware(options);
};

export const store = configureStore({
  reducer: persistedReducer,
  middleware: middlewareConfig,
  devTools: import.meta.env.PROD,
  enhancers: getDefaultEnhancers =>
    getDefaultEnhancers().concat([sentryReduxEnhancer, applyMiddleware()]),
});

export const persistor = persistStore(store);
