import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from '@brasshq/ui-kit';
import { withErrorBoundary } from '@sentry/react';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from 'src/redux/store';

import ErrorFallbackRender from 'src/sharedComponents/ErrorBoundary';
import PageLoader from 'src/sharedComponents/PageLoader';

import 'src/styles/main.scss';
import '@brasshq/ui-kit/brass-dark.css';

const AppRouter = React.lazy(
  () => import(/* webpackChunkName: "AppRouter" */ './AppRouter'),
);

const AppInitializer = React.lazy(
  () => import(/* webpackChunkName: "AppInitializer" */ './AppInitializer'),
);

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={<PageLoader />} persistor={persistor}>
          <AppInitializer>
            <AppRouter />
            <ToastContainer />
          </AppInitializer>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  );
};

export default withErrorBoundary(App, {
  fallback: <ErrorFallbackRender />,
});
