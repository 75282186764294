import React from 'react';

import { Country, CurrencyData, OtpChannel, Profile } from 'src/types';

export type DefaultValuesType = {
  country: Country | null;
  hasSessionTimedOut: boolean;
  profile: Profile | null;
  currency: CurrencyData;
  otpChannel: OtpChannel | null;
};

export const DEFAULT_VALUES: DefaultValuesType = {
  country: null,
  profile: null,
  hasSessionTimedOut: false,
  currency: {
    code: 'NGN',
    symbol: '₦',
  },
  otpChannel: null,
};

export const CommonContext = React.createContext(DEFAULT_VALUES);

CommonContext.displayName = 'Common';
