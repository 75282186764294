import { AxiosError } from 'axios';
import { isArray } from 'lodash';

import { handleError } from 'src/utils/errorHandlerUtils';

import { IObject } from 'src/types';

export async function AsyncWrapper<T = any>(
  promise: Promise<any>,
  errorHandler?: (e: AxiosError<IObject>) => void,
) {
  const result: [T | null, any] = [null, null];
  try {
    const payload = await promise;
    if (isArray(payload)) result[0] = payload.map(item => item.data) as T;
    else result[0] = await payload.data;
  } catch (e: any) {
    result[1] = e;
    if (errorHandler) {
      errorHandler(e);
    } else {
      handleError(e);
    }
  }
  return result;
}
