import { window } from 'browser-monads';

import { generateServiceJwt } from 'src/modules/common/functions';

import { ZENDESK_ID, ZENDESK_URL, ZENDESK_URL_KEY } from 'src/data/env';

enum ZendeskAction {
  Show = 'show',
  Hide = 'hide',
  Open = 'open',
  Close = 'close',
  Login = 'loginUser',
  Logout = 'logoutUser',
}

export enum ZendeskAuthType {
  Anonymous,
  User,
}

const handleZendeskAction = (type: ZendeskAction, cb?: (x?: any) => void) => {
  if (window?.zE) window?.zE('messenger', type, cb);
  return null;
};

const hideLauncherOnClose = () => {
  return (
    window?.zE &&
    window.zE('messenger:on', 'close', () =>
      handleZendeskAction(ZendeskAction.Hide),
    )
  );
};

const initializeZendesk = (authType: ZendeskAuthType) => {
  hideLauncherOnClose();
  if (authType === ZendeskAuthType.User) login();
  else hide();
};

export const initialize = (authType: ZendeskAuthType) => {
  if (!ZENDESK_ID && !ZENDESK_URL_KEY && !ZENDESK_URL) {
    throw Error(
      'Missing environment variables for Zendesk key, id and widget URL',
    );
  }
  if (!window?.zE) {
    const scriptElem: any = document.createElement('script');
    scriptElem.async = true;
    scriptElem.id = ZENDESK_ID;
    scriptElem.src = `${ZENDESK_URL}?key=${ZENDESK_URL_KEY}`;
    scriptElem.onload = () => initializeZendesk(authType);
    document.body.appendChild(scriptElem);
  }
};

const login = () => {
  handleZendeskAction(ZendeskAction.Login, async cb => {
    hide();
    const res = await generateServiceJwt({ service: 'zendesk' });
    res && cb(res.data.jwt);
  });
};

export const show = () => {
  handleZendeskAction(ZendeskAction.Show);
  handleZendeskAction(ZendeskAction.Open);
};

export const hide = () => {
  handleZendeskAction(ZendeskAction.Hide);
  handleZendeskAction(ZendeskAction.Close);
};

export const logout = () => {
  handleZendeskAction(ZendeskAction.Logout);
};
