import { createAsyncThunk } from '@reduxjs/toolkit';

import { AsyncWrapper } from 'src/helpers/asyncWrapper';

import { DefaultFetchParams, SalaryAccount } from 'src/types';

import { getAccounts } from '../api';

export const fetchAccounts = createAsyncThunk<
  {
    data: SalaryAccount[];
    meta: {
      salary_account_id: string;
    };
  },
  DefaultFetchParams | undefined
>('bankAccounts/accounts', async (params, { rejectWithValue }) => {
  const [result, error] = await AsyncWrapper(
    getAccounts({ ...params, include: `${params?.include},bank,user` }),
  );
  if (error) return rejectWithValue(error.response?.data);
  return result;
});
