import { AsyncWrapper } from 'src/helpers/asyncWrapper';

import { getVerifyBankAccount, postGenerateServiceJwt } from './api';
import { ATGenerateServiceJwt, ATVerifyBankAccount } from './types';

export const verifyBankAccount: ATVerifyBankAccount = async params => {
  let bank = null;
  const [response] = await AsyncWrapper(
    getVerifyBankAccount({ useApiKey: true, ...params }),
  );
  if (response) {
    const {
      data: {
        bank: {
          data: { id },
        },
        account_name,
        allow_setting_name,
      },
    } = response;
    bank = {
      bank: id as string,
      name: account_name as string,
      allow_setting_name,
    };
  }
  return bank;
};

export const generateServiceJwt: ATGenerateServiceJwt = async data => {
  const [result] = await AsyncWrapper(postGenerateServiceJwt(data));
  return result;
};
