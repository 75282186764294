import { createSlice } from '@reduxjs/toolkit';

import { isLogoutAction } from 'src/utils/authUtils';

import { ReduxStore } from 'src/types';

import { fetchPayslips } from './actions';

type PaymentsState = ReduxStore['module/payments'];

const initialState: PaymentsState = {
  payslips: {
    data: [],
    loading: false,
    pagination: {
      total_pages: 1,
      per_page: 10,
      current_page: 1,
    },
  },
};

const paymentsSlice = createSlice({
  name: 'module/payments',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchPayslips.pending, state => {
        state.payslips = {
          ...state.payslips,
          loading: true,
        };
      })
      .addCase(fetchPayslips.fulfilled, (state, action) => {
        state.payslips = {
          ...state.payslips,
          data: action.payload?.data || [],
          pagination: action.payload.meta.pagination,
          loading: false,
        };
      })
      .addCase(fetchPayslips.rejected, (state, action: any) => {
        state.payslips = {
          ...state.payslips,
          data: [],
          loading: false,
          error: action.payload?.error.description,
        };
      })
      .addMatcher(isLogoutAction, () => initialState);
  },
});

export const paymentsReducer = paymentsSlice.reducer;
