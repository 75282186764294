import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import { reduxStorage } from 'src/helpers/storage';

import {
  REDUX_PERSIST_THROTTLE,
  REDUX_PERSIST_VERSION,
} from 'src/data/constants';
import { BRASS_APP_REDUX_PERSIST_KEY } from 'src/data/env';

export const REDUX_PERSIST_CONFIG = {
  key: BRASS_APP_REDUX_PERSIST_KEY,
  storage: reduxStorage,
  version: REDUX_PERSIST_VERSION,
  stateReconciler: autoMergeLevel2,
  throttle: REDUX_PERSIST_THROTTLE,
  debug: import.meta.env.DEV,
  whitelist: ['module/auth', 'module/common'],
};
