import * as ROUTES from 'src/data/apiRoutes';

import { DefaultFetchParams } from 'src/types';

import { BrassApiService } from 'src/services';

import {
  RDTConnectAccount,
  RDTUpdateEmployeePreference,
} from './types/requests';

export const getAccounts = (params?: DefaultFetchParams) =>
  BrassApiService.get(ROUTES.accountsRoute, { params });

export const postConnectAccount = (data: RDTConnectAccount) =>
  BrassApiService.post(ROUTES.accountsRoute, data);

export const patchAccount = (data: RDTUpdateEmployeePreference) =>
  BrassApiService.patch(ROUTES.employeePreferenceRoute, data);

export const deletedAccount = (id: string) =>
  BrassApiService.delete(`${ROUTES.accountsRoute}/${id}`);
