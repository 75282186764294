import { createAsyncThunk } from '@reduxjs/toolkit';

import { AsyncWrapper } from 'src/helpers/asyncWrapper';

import * as api from '../api';

export const fetchStatesList = createAsyncThunk(
  'common/fetchStatesList',
  async () => {
    const [states] = await AsyncWrapper(
      api.getStatesList({
        country: 'NG',
        limit: 1000,
        useApiKey: true,
        include: 'country',
      }),
      () => null,
    );
    return states;
  },
);

export const fetchCountriesList = createAsyncThunk(
  'common/fetchCountriesList',
  async () => {
    const [countries] = await AsyncWrapper(
      api.getCountriesList({
        useApiKey: true,
        limit: 1000,
      }),
      () => null,
    );
    return countries;
  },
);

export const fetchIndustries = createAsyncThunk(
  'common/fetchIndustries',
  async () => {
    const [industries] = await AsyncWrapper(
      api.getIndustries({
        useApiKey: true,
        limit: 1000,
      }),
      () => null,
    );
    return industries;
  },
);

export const fetchBankList = createAsyncThunk(
  'common/fetchBankList',
  async (country: string) => {
    const [banks] = await AsyncWrapper(
      api.getBanksList({ useApiKey: true, limit: 1000, country }),
      () => null,
    );
    return banks;
  },
);

export const fetchPfaList = createAsyncThunk(
  'common/fetchPfaList',
  async (customer_id: string) => {
    const [pfas] = await AsyncWrapper(
      api.getPfaList({ customer_payroll_profile: customer_id, limit: 100 }),
      () => null,
    );
    return pfas;
  },
);
