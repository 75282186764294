class AppStorageClass {
  storage = localStorage;
  constructor(storage?: any) {
    if (storage) this.storage = storage;
  }
  clear = this.storage.clear;
  set = (key: string, value: string) => {
    return this.storage.setItem(key, value);
  };
  has = (key: string) => {
    return !!this.get(key);
  };
  get = (key: string) => {
    return this.storage.getItem(key);
  };
  remove = (key: string) => {
    return this.storage.removeItem(key);
  };
  setAll = (items: Array<[key: string, value: string]>) => {
    items.map(item => this.set(...item));
    return;
  };
  getAll = (keys: string[]) => {
    return keys.map(async key => ({
      [key]: await this.get(key),
    }));
  };
  removeAll = (keys: string[]) => {
    keys.map(key => this.remove(key));
    return;
  };
}

export const AppStorage = new AppStorageClass();
