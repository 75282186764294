import { createSlice } from '@reduxjs/toolkit';
import { pick } from 'lodash';

import { ReduxStore } from 'src/types';

import { fetchCards, fetchCardTransactions } from './actions';

type CardsState = ReduxStore['module/cards'];

const initialState: CardsState = {
  list: {
    data: [],
    loading: false,
    pagination: {
      total_pages: 1,
      per_page: 10,
      current_page: 1,
    },
  },
  transactions: {
    data: [],
    loading: false,
    pagination: {
      total_pages: 1,
      per_page: 10,
      current_page: 1,
    },
  },
};

const cardsSlice = createSlice({
  name: 'module/cards',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchCards.pending, state => {
        state.list.loading = true;
      })
      .addCase(fetchCards.fulfilled, (state, action) => {
        state.list = {
          data: action.payload?.data,
          loading: false,
          pagination: pick(action.payload.meta.pagination, [
            'current_page',
            'per_page',
            'total_pages',
            'total',
          ]),
        };
      })
      .addCase(fetchCards.rejected, state => {
        state.list = {
          ...state.list,
          data: [],
          loading: false,
        };
      })
      .addCase(fetchCardTransactions.pending, state => {
        state.transactions.loading = true;
      })
      .addCase(fetchCardTransactions.fulfilled, (state, action) => {
        state.transactions = {
          data: action.payload?.data,
          loading: false,
          pagination: pick(action.payload.meta.pagination, [
            'current_page',
            'per_page',
            'total_pages',
            'total',
          ]),
        };
      })
      .addCase(fetchCardTransactions.rejected, state => {
        state.transactions = {
          ...state.transactions,
          data: [],
          loading: false,
        };
      });
  },
});

export const cardsReducer = cardsSlice.reducer;
