import { createSlice } from '@reduxjs/toolkit';

import { ReduxStore } from 'src/types';

import { fetchInvitationDetails, fetchPayrollProfile } from './actions';

type InvitationReducer = ReduxStore['module/invitation'];

const initialState: InvitationReducer = {
  payrollProfile: {
    data: null,
  },
  details: {
    data: null,
  },
};

const invitationSlice = createSlice({
  name: 'module/invitation',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchPayrollProfile.pending, state => {
        state.payrollProfile.loading = true;
      })
      .addCase(fetchPayrollProfile.fulfilled, (state, action) => {
        state.payrollProfile = {
          data: action.payload?.data || null,
          loading: false,
        };
      })
      .addCase(fetchPayrollProfile.rejected, (state, action: any) => {
        state.payrollProfile = {
          data: null,
          loading: false,
          error: action.payload?.error.description,
        };
      })
      .addCase(fetchInvitationDetails.pending, state => {
        state.details.loading = true;
      })
      .addCase(fetchInvitationDetails.fulfilled, (state, action) => {
        state.details = {
          data: action.payload?.data || null,
          loading: false,
        };
      })
      .addCase(fetchInvitationDetails.rejected, (state, action: any) => {
        state.details = {
          data: null,
          loading: false,
          error: action.payload?.error.description,
        };
      });
  },
});

export const invitationReducer = invitationSlice.reducer;
