import { useNavigate } from 'react-router-dom';
import { Button, ButtonVariant } from '@brasshq/ui-kit';

export const BackButton = () => {
  const navigate = useNavigate();
  return (
    <Button onClick={() => navigate(-1)} variant={ButtonVariant.Primary}>
      Return to last page
    </Button>
  );
};
