//Common routes
export const industriesRoute = '/industries';
export const statesListRoute = '/states';
export const countriesListRoute = '/countries';
export const bankListRoute = '/banking/banks';
export const verifyBankAccountRoute = `${bankListRoute}/account-name`;
export const generateServiceJwtRoute = '/auth/me/jwts';

// Auth routes
export const employeesRoute = '/payroll/employees';
export const authRoute = `${employeesRoute}/auth`;
export const loginRoute = `${employeesRoute}/auth/login`;
export const resetPasswordRoute = '/auth/passwords/request-reset';
export const activeSessionRoute = `/auth/me`;
export const employeeProfileRoute = `${employeesRoute}/auth/me`;
export const verifyPasswordHashRoute = (hash: string) =>
  `auth/passwords/verify-hash/${hash}`;
export const changePasswordRoute = (hash: string) =>
  `auth/passwords/reset-password/${hash}`;

//Invitation routes
export const joinEmployeeRoute = `${authRoute}/join`;
export const registerEmployeeRoute = `${authRoute}/register`;
export const payrollProfileRoute = `${employeesRoute}/companies`;
export const salaryAccountRoute = `${employeesRoute}/accounts`;
export const employeeInviteRoute = `${employeesRoute}/invitations`;
export const pfaRoute = `${employeesRoute}/pfas`;
export const otpRoute = '/auth/otp';

//Accounts routes
export const accountsRoute = `${employeesRoute}/accounts`;

// Employee Preference routes
export const employeePreferenceRoute = `${employeesRoute}/preferences`;

// Payslip routes
export const payslipsRoute = `${employeesRoute}/payslips`;

// Cards routes
export const cardsRoute = '/cards';
export const employeeCardsRoute = `${cardsRoute}/employees`;
export const employeeCardTransactionsRoute = `${employeeCardsRoute}/transactions`;

export const reportTransactionRoute = '/finsec/reports';
export const ledgersRoute = '/banking/ledgers';
