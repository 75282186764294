import { createAsyncThunk } from '@reduxjs/toolkit';

import { AsyncWrapper } from 'src/helpers/asyncWrapper';

import { Card, Ledger, Payload } from 'src/types';

import * as api from '../api';
import { ATGetTransactionsParams } from '../types';
import { RDTFetchCardsListParams } from '../types/requests';

export const fetchCards = createAsyncThunk<
  Payload<Card[]>,
  RDTFetchCardsListParams
>('cards/fetchCards', async (params, { rejectWithValue }) => {
  const [data, error] = await AsyncWrapper(api.getCards({ ...params }));
  if (error) return rejectWithValue(error.response?.data);
  return data;
});

export const fetchCardTransactions = createAsyncThunk<
  Payload<Ledger[]>,
  ATGetTransactionsParams
>(
  'cards/fetchCardTransactions',
  async ({ cardId, params }, { rejectWithValue }) => {
    const [data, error] = await AsyncWrapper(
      api.getTransactions({
        ...params,
        causer: cardId,
      }),
    );
    if (error) return rejectWithValue(error.response?.data);
    return data;
  },
);
