import { Action, UnknownAction } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { CookieAttributes } from 'node_modules/@types/js-cookie';

import {
  ACCOUNT_LINK_OTP_TOKEN_KEY,
  AUTH_TOKEN_KEY,
  OTP_TOKEN_KEY,
  SESSION_TIMEOUT_OTP_TOKEN_KEY,
} from 'src/data/constants';
import { COOKIE_DOMAIN } from 'src/data/env';

import { IObject, TokenKey } from 'src/types';

import { generateCookieExpiry } from './genericUtils';

const TOKEN_ID_TO_KEY_MAP: IObject<string> = {
  auth: AUTH_TOKEN_KEY,
  otp: OTP_TOKEN_KEY,
  accountLink: ACCOUNT_LINK_OTP_TOKEN_KEY,
  sessionTimeoutOtp: SESSION_TIMEOUT_OTP_TOKEN_KEY,
};

export const isUserLoggedIn = !!Cookies.get(TOKEN_ID_TO_KEY_MAP['auth']);

export const getToken = (id: TokenKey): string | undefined => {
  return Cookies.get(TOKEN_ID_TO_KEY_MAP[id]);
};

export const setToken = (id: TokenKey, token: string) => {
  const cookie = Cookies.get(TOKEN_ID_TO_KEY_MAP[id]);
  const config = getCookieConfig(id);
  if (cookie) {
    removeCookie(TOKEN_ID_TO_KEY_MAP[id], config);
  }
  return Cookies.set(TOKEN_ID_TO_KEY_MAP[id], token, config);
};

export const clearToken = (id: TokenKey): void => {
  if (id === '*') {
    return Object.keys(TOKEN_ID_TO_KEY_MAP).forEach(tokenId => {
      const config = getCookieConfig(tokenId as TokenKey);
      removeCookie(TOKEN_ID_TO_KEY_MAP[tokenId], config);
    });
  }
  const config = getCookieConfig(id);
  return removeCookie(TOKEN_ID_TO_KEY_MAP[id], config);
};

const removeCookie = (key: string, config: CookieAttributes) => {
  const cookie = Cookies.get(key);
  if (cookie) {
    Cookies.remove(key, config);
  }
};

const getCookieConfig = (id: TokenKey) => {
  const defaultConfig: CookieAttributes = {
    domain: COOKIE_DOMAIN,
    secure: import.meta.env.PROD,
    expires: generateCookieExpiry(10),
  };
  if (TOKEN_ID_TO_KEY_MAP[id] === TOKEN_ID_TO_KEY_MAP.auth)
    defaultConfig.expires = 1;
  return defaultConfig;
};

export const isLogoutAction = (action: UnknownAction): action is Action => {
  return action.type.endsWith('logout');
};
