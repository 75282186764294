import { AxiosRequestConfig } from 'axios';

import {
  employeeCardsRoute,
  employeeCardTransactionsRoute,
  ledgersRoute,
  reportTransactionRoute,
} from 'src/data/apiRoutes';

import { BrassApiService } from 'src/services';
import BrassEverVaultServices from 'src/services/brassEverVaultService';

import {
  RDTActivateCard,
  RDTFetchCardsListParams,
  RDTGetCardDetailsParams,
  RDTGetTransactionDetailsParams,
  RDTGetTransactionsParams,
  RDTUpdateCardPin,
  RDTUpdateCardStatus,
} from './types';

export const getCards = (
  params: RDTFetchCardsListParams,
  config?: Omit<AxiosRequestConfig, 'params'>,
) => {
  return BrassApiService.get(employeeCardsRoute, {
    params,
    ...config,
  });
};

export const getCardsDetails = (
  id: string,
  params?: RDTGetCardDetailsParams,
) => {
  return BrassApiService.get(`${employeeCardsRoute}/${id}`, {
    params,
  });
};

export const getUnecryptedCardDetails = (
  id: string,
  params: RDTGetCardDetailsParams,
) => {
  return BrassEverVaultServices.get(`${employeeCardsRoute}/${id}`, { params });
};

export const postActivateCard = (id: string, data: RDTActivateCard) => {
  return BrassApiService.post(`${employeeCardsRoute}/${id}`, data);
};

export const patchUpdateCardStatus = (
  id: string,
  data: RDTUpdateCardStatus,
) => {
  return BrassApiService.patch(`${employeeCardsRoute}/${id}/statuses`, data);
};

export function patchUpdateCardPin(
  id: string,
  data: RDTUpdateCardPin,
): Promise<any> {
  return BrassApiService.patch(`${employeeCardsRoute}/${id}/pins`, data);
}

export const getTransactions = (params: RDTGetTransactionsParams) => {
  return BrassApiService.get(`${employeeCardTransactionsRoute}`, {
    params,
  });
};

export const getTransaction = ({
  id,
  ...params
}: RDTGetTransactionDetailsParams) => {
  return BrassApiService.get(`${employeeCardTransactionsRoute}/${id}`, {
    params,
  });
};

export const getTransactionReceipt = (id: string) => {
  return BrassApiService.put(`${ledgersRoute}/${id}/receipt`);
};

export const reportTransaction = (params: { id: string; reason: string }) => {
  return BrassApiService.post(`${reportTransactionRoute}`, params);
};
