import { ButtonVariant } from '@brasshq/ui-kit';
import { format } from 'date-fns';

export const REDUX_PERSIST_VERSION = 1;
export const REDUX_PERSIST_THROTTLE = 500;

export const BRASS_LAUNCH_MONTH = 8; // August

export const BRASS_LAUNCH_YEAR = 2020;
export const CURRENT_YEAR = new Date().getFullYear();
export const DATE_FORMAT = 'yyyy-MM-dd';
export const TODAY_OBJ = new Date();
export const TODAY = format(TODAY_OBJ, DATE_FORMAT);
export const DIALOG_DATA_DEFAULTS = {
  title: '',
  description: '',
  successMessage: '',
  action: {
    variant: ButtonVariant.Primary,
    label: '',
  },
  request: async () => null,
};

export const NIGERIALOGOS_LOGO_BASE_URL = 'https://nigerialogos.com/logos';

export const COUNTRY_ACCOUNT_NUMBER_LIMIT: {
  [x: string]: {
    min: number;
    max: number;
  };
} = {
  NG: {
    min: 10,
    max: 10,
  },
  KE: {
    min: 10,
    max: 20,
  },
};

export const CURRENCY_SYMBOL: {
  [K in string]: string;
} = {
  KES: 'Ksh',
  NGN: '₦',
};

export const GENDER = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
];

export const AUTH_TOKEN_KEY = 'Brass-Employee-App/AuthToken';
export const OTP_TOKEN_KEY = 'Brass-Employee-App/OtpToken';
export const ACCOUNT_LINK_OTP_TOKEN_KEY =
  'Brass-Employee-App/AccountLinkOtpToken';
export const SESSION_TIMEOUT_OTP_TOKEN_KEY =
  'Brass-Employee-App/SessionTimeoutOtpToken';

export const TRANSACTION_REPORT_REASON = [
  {
    value: 'Debited but not received at counterparty end',
  },
  {
    value: 'Delayed transactions',
  },
  {
    value: 'Transaction processing but debit has occurred',
  },
  {
    value: 'Payment processing failed',
  },
  {
    value: 'Successful transaction with no debit on account',
  },
  {
    value: 'Others',
  },
];

export const DEVICE_SCREEN_SIZES = {
  mobile_min: 375,
  mobile_standard: 425,
  mobile_lg: 450,
  tablets: 768,
  laptop_min: 1024,
  laptop_standard: 1440,
};
