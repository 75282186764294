import localforage from 'localforage';

import { AppStorage } from './appStorage';

export const networkStorage = localforage.createInstance({
  name: 'networkStorage',
  version: 1.0,
  storeName: 'networkStorage',
});

export const reduxStorage = localforage.createInstance({
  name: 'reduxStorage',
  version: 1.0,
  storeName: 'reduxStorage',
});

export const clearStorage = () => {
  AppStorage.clear();
  networkStorage.clear();
  reduxStorage.clear();
};
